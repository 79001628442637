<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/personApplication/list">审批人员管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="姓名" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
     <el-form-item label="电话" prop="telephone">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.telephone"
        ></el-input>
      </el-form-item>
     <el-form-item label="状态" prop="status">
        <el-select v-model="queryModel.status" placeholder="请选择" size="mini" >
            <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
    >
      <el-table-column prop="name" label="申请人姓名" width="120"></el-table-column>
      <el-table-column
        prop="applicantTime"
        label="申请时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="申请公司"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="personRoleName"
        label="申请角色"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="telephone"
        label="电话号码"
        width="130"
      ></el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号"
        width="180"
      ></el-table-column>
      <el-table-column prop="faceImageUrl"  label="人脸" width="120" >
            <template slot-scope="{ row }">
                <a :href="row.faceImageUrl" target="_blank">
                    <el-image
                    :size="48"
                    :src="
                        row.faceImageUrl + '?x-oss-process=image/resize,m_fill,w_64,h_64'
                    "
                    :key="row.id"
                    ><div slot="error">
                        <i class="el-icon-picture-outline" style="width:64px;"></i></div
                    ></el-image>
                </a>
            </template>
      </el-table-column>
    <el-table-column prop="statusN" label="状态" width="120">
        <template slot-scope="{ row }">
            <span v-if="row.status=='1'" style="color:green">{{row.statusN}}</span>
            <span v-else-if="row.status=='2'" style="color:red">{{row.statusN}}</span>
            <span v-else >{{row.statusN}}</span>
        </template>
    </el-table-column>
    <el-table-column
        prop="address"
        label="地址"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{row}">
          <!-- <el-button v-if="row.status=='0'" size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button v-if="row.status=='0'" size="mini" type="danger" @click="handleDelete(row)">删除</el-button> -->
            <el-row v-if="row.status=='0'">
                <el-col :span="12">
                    <el-link type="primary" @click="handleEdit(row)">编辑</el-link>
                </el-col>
                <el-col :span="12">
                    <el-link type="danger" @click="handleDelete(row)">删除</el-link>
                </el-col>
            </el-row>
            <el-row v-if="row.status=='0'">
                <el-col :span="12">
                    <el-link type="success" @click="handleCheck(row,1)">同意</el-link>
                </el-col>
                <el-col :span="12">
                    <el-link type="danger" @click="handleCheck(row,2)">拒绝</el-link>
                </el-col>
            </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personApplication-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></personApplication-detail>
  </div>
</template>
<script>
import personApplicationDetail from "./personApplication-detail";
import personApplicationApi from "@/api/base/personApplication";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name:"basePersonApplicationList",
  data() {
    return {
      queryModel: {
          name:"",
          telephone:"",
          status:""
      },
      statusList:[{
            label:"待审核",
            value:"0"
        },{
            label:"同意",
            value:"1"
        },{
            label:"拒绝",
            value:"2"
        }],
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      treeData: [],
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("name", self.queryModel.name);
      formData.append("telephone", self.queryModel.telephone);
      formData.append("status", self.queryModel.status);

      personApplicationApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleCheck(record,status){
        var self = this;

        var formData = new FormData();

        formData.append("id", record.id);
        formData.append("status", status);

        this.$confirm("是否确认审批？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
        }).then(() => {
            personApplicationApi.statusCheck(formData).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
                self.changePage(self.pageIndex);

                self.$message({
                type: "success",
                message: "审核成功!",
                });
            }
            });
        });
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          personApplicationApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        personApplicationApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "personApplication-detail": personApplicationDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>