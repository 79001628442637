import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/personApplication/pageList", formData);
}

function pageCommunityList(formData){
  return request.post(constant.serverUrl + "/base/personApplication/pageCommunityList", formData);
}


function create(){
  return request.get(constant.serverUrl + "/base/personApplication/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/personApplication/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/personApplication/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/personApplication/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/personApplication/delete/" + id);
}


function statusCheck(formData){
  return request.post(constant.serverUrl + "/base/personApplication/statusCheck", formData);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/personApplication/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,statusCheck,pageCommunityList
}