
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    width="40%"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :label-width="'100px'"
      >
        <el-form-item label="申请人姓名" prop="name">
          <el-input
            v-model="formModel.name"
            placeholder="请输入标题"
            style="width: 300px"
            readonly="readonly"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司" prop="companyId">
            <el-select-tree
            :props="props"
            :options="treeData"
            v-model="formModel.companyId"
            style="width: 300px"
            ></el-select-tree>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import personApplicationApi from "@/api/base/personApplication";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
        formModel: {},
        showDialog: true,
        loading: false,
        submitting: false,
        treeData: [],
        props: {
            // 配置项（必选）
            value: "id",
            label: "name",
            children: "children"
        },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    loadTree() {
      companyInfoApi.treeList().then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return personApplicationApi.add(self.formModel);
            } else {
              return personApplicationApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return personApplicationApi.create();
      } else {
        return personApplicationApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
    },
    components: {
        "el-select-tree": SelectTree
    },
    created() {
        this.loadTree();
    },
};
</script>